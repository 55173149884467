@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Petchlamoon Bold';
    src: url('../fonts/petchlamoon-bold.woff') format('woff'),
    url('../fonts/petchlamoon-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
    scroll-behavior: smooth;
}

.App {
    text-align: center;
}

.welcome-name {
    font-family: 'Petchlamoon Bold', sans-serif;
    color: white;
    text-shadow: rgb(192 132 252) 0 0 10px;
}

h2 {
    font-family: 'Petchlamoon Bold', sans-serif;
}

.about-button {
    position: relative; /* Required for absolute positioning of the pseudo-element */
    overflow: hidden; /* Ensures the pseudo-element doesn't spill outside the button */
    background-color: rgb(192 132 252);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 2rem;
    font-family: 'Petchlamoon Bold', sans-serif;
    font-size: 1.2rem;
    cursor: pointer;
    transition: color 0.3s; /* Transition for text color */
    box-shadow: #c084fc 0 0 10px;
}

.about-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgb(168 85 247);
    transition: width 0.5s ease;
    z-index: -1;
}

.about-button:hover::before {
    width: 100%; /* Extend the pseudo-element to cover the entire button */
}

.project-view {
    position: relative; /* Required for absolute positioning of the pseudo-element */
    overflow: hidden; /* Ensures the pseudo-element doesn't spill outside the button */
    background-color: rgb(192 132 252);
    color: white;
    border: none;
    border-radius: 2rem;
    font-size: 1.2rem;
    cursor: pointer;
    transition: color 0.3s; /* Transition for text color */
}

.project-view::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgb(168 85 247);
    transition: width 0.5s ease;
    z-index: -1;
}

.project-view:hover::before {
    width: 100%; /* Extend the pseudo-element to cover the entire button */
}

#header {
    position: sticky;
    top: 0;
    z-index: 49; /* A high z-index to ensure it's above other content */
}

nav {
    font-weight: bold;
}

/* The attention grabber section stays fixed */
#attention-grabber {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Adjust if you want it smaller */
    z-index: 1; /* Keeps it below the content that will scroll over it */
    /* Add more styles here */
}


/* The other sections scroll over the attention grabber */
#content {
    position: relative;
    z-index: 2; /* Ensure it's above the attention grabber */
    margin-top: 100vh; /* Push it down so the attention grabber can show */
    /* Add more styles here */
}


.bubble-wrapper {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    transition: transform 0.3s ease;
    width: 20vw;
}

.bubble-wrapper img {
    display: block;
    transition: transform 0.3s ease;
}

.bubble-wrapper:hover {
    transform: scale(1.05);
}

.swiper-button-next, .swiper-button-prev {
    --swiper-navigation-color: rgb(192 132 252) !important;
}

.skills-carousel-wrapper {
    overflow: hidden;
    width: 58%; /* Of stel een vaste breedte in */
    /* Optioneel: stel een hoogte in of laat deze bepaald worden door de inhoud */
}

.skills-carousel {
    display: flex;
    /* De animatie wordt nu dynamisch toegevoegd via JavaScript */
}

.skill-logo {
    width: 50px; /* Aan te passen indien nodig */
    height: 50px; /* Aan te passen indien nodig */
    margin: 0 10px; /* Aan te passen indien nodig */
    flex-shrink: 0; /* Zorgt ervoor dat de logo's niet krimpen */
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: none; /* Optional: if you want a dotted underline or any indicator */
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 5rem;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -2.5rem; /* Use half of the width to center the tooltip */
    /* Fade in animation */
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 768px) {
    .bubble-wrapper {
        width: 40vw;
    }

    .skills-carousel-wrapper {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .title {
        flex-direction: column;
    }
}

.star {
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    animation: twinkle linear infinite;
    z-index: 1; /* Ensure stars appear behind text */
}

@keyframes twinkle {
    0%, 100% {
        opacity: 0;
        background-color: rgb(192 132 252);
    }
    50% {
        opacity: 1;
        background-color: white;
    }
}

